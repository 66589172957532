import { createI18n } from 'vue-i18n'
import English from '@/Lang/en.json'
import Spanish from '@/Lang/es.json'
const i18n = createI18n(
    {
        legacy:false,
        locale: 'en',
        fallbackLocale: 'en',
        messages: {
            en: English,
            es: Spanish,
        },
    }
);

export default i18n

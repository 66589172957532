import './bootstrap';
import '../css/app.css';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import PrimeVue from 'primevue/config';
import Theme from '@primevue/themes/lara';
import Ripple from 'primevue/ripple';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import {Link} from "@inertiajs/vue3";
import i18n from '@/Lang/LangConfig.js'
import Card from "primevue/card";
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';


const app = createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(i18n)
            .use(ZiggyVue)
            .use(CkeditorPlugin)
            .component('Link', Link)
            .component('Card', Card)
            .use(PrimeVue, {
                theme: {
                    preset: Theme,
                    options: {
                        darkModeSelector: '.my-app-dark',
                    }
                },
                ripple:true,
            })
            .use(ToastService)
            .directive('ripple', Ripple)
            .directive('tooltip', Tooltip)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});

